<template>
  <div class="register-page register-landing-page">
    <div class="container-wide landing">
      <img src="/static/img/logo-soco.svg" class="logo-soco" alt="register-cover-image" />
      <h2 class="register-tile deep-space">Belum mempunyai akun SOCO?</h2>
      <p class="main-desc text-center">
        <strong>Bergabunglah dalam komunitas yang mengerti keinginan kamu!</strong>
      </p>
      <p class="main-desc text-center mb-34">
        Dapatkan keleluasaan untuk berbelanja puluhan ribu produk kecantikan dan akses ke review serta artikel dalam
        satu platform tanpa batas.
        <br />Yuk, atur sendiri dunia barumu, daftar sekarang juga.
      </p>

      <div v-if="isLoggedin && isLoggedin.id" class="mb-45 text-center error">
        <a href class="btn" style="min-width: 260px" @click.prevent="logout()">LOG OUT</a>
        <p class="alert text-error">Session user sebelumnya masih terhubung, silakan logout terlebih dahulu</p>
      </div>
      <router-link v-else to="/register/account" class="btn mb-45">DAFTAR SEKARANG</router-link>

      <p class="main-desc text-center mb-30">Aplikasi mobile SOCO tersedia di iOS dan Android</p>
      <div class="apps-button-wrap">
        <a href="#" class="apps-btn">
          <img src="/static/img/ios.svg" alt="ios" />
        </a>
        <a href="#" class="apps-btn">
          <img src="/static/img/gplay.png" alt="android" />
        </a>
      </div>
      <img class="cover-img" src="/static/img/login-cover-img.png" alt="register-cover-image" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'register',
  components: {},
  data() {
    return {};
  },
  computed: {
    isLoggedin() {
      return this.$store.state.auth.userProfile;
    },
  },
  created() {},
  methods: {
    logout() {
      this.$MS_SOCO_PUBLIC_API_URL.get('/auth/logout').then((res) => {
        if (res.data.success) {
          this.$toasted.global.show('Logout berhasil');
          location.reload();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.register-page {
  .mb-45.text-center.error {
    position: relative;
    width: 100%;
    p.alert.text-error {
      line-height: 16px;
      padding: 0;
      margin-top: 10px;
      color: #b32656;
      position: absolute;
      white-space: nowrap;
      left: 0;
      right: 0;
    }
  }
}
</style>
